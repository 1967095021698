import React, { useRef, useEffect } from 'react'
import { graphql } from 'gatsby'
import { Row, Col } from 'antd'
import PublicLayout from "../components/PublicLayout"
import { Player } from 'video-react'
import "../../node_modules/video-react/dist/video-react.css"
import SEO from '../components/seo'
import { useTranslation } from 'react-i18next'

const AgreementTutorial = ({ pageContext }) => {
  const { type } = pageContext
  const player = useRef()
  const { t } = useTranslation();

  useEffect(() => {
    if (player) {
      player.current.subscribeToStateChange(handleBind)
    }
  }, [])

  const handleBind = (state) => {
    if (state.ended) {
      player.current.video.load()
    }
  }

  const schemaExtraData = [{
    "@type": "Organization",
    "logo": "https://cmtsaludproduction.s3.sa-east-1.amazonaws.com/resources/logo_naranjo2.png",
    "sameAs": [
      "https://www.facebook.com/CentroMedicoDelTrabajador",
      "https://www.linkedin.com/company/centro-medico-del-trabajador"
    ],
  }]


  return (
    <PublicLayout>
      <SEO
        title={type.name === "authorization" ? t('seo.agreementTutorial.autorizationTitle') : t('seo.agreementTutorial.ocTitle')}
        description={type.name === "authorization" ? t('seo.agreementTutorial.autorizationDescription') : t('seo.agreementTutorial.ocDescription')}
        dataLayer={{
          userid: "",
          pageId: "",
          pageType: "",
          category: "",
        }}
        schemaExtraData={schemaExtraData}
      />
      <Row justify="center" className="mt-60 mb-60">
        <Col lg={20} className="m-xs-20">
          <h1>
            {type.name === "authorization" ? "Tutorial Convenio Autorización" : "Tutorial Convenio OC"}
          </h1>
        </Col>
        {
          type.name === "authorization" ? (
            <Col lg={20} className="m-xs-20">
              <p>
                En CMT hemos mejorado nuestro proceso de facturación de convenios. A partir de abril de 2021 encontrarás en el correo que recibes a principios de cada mes un enlace para revisar las prestaciones realizadas el mes anterior, donde podrás dar autorización para la emisión de la factura correspondiente.
              </p>
              <p>
                A continuación, te dejamos un video explicativo de como autorizar la emisión de facturas:
              </p>
              <Player
                ref={player}
                src="https://cmtsaludproduction.s3-sa-east-1.amazonaws.com/resources/authorization.mp4"
                poster="https://cmtsaludproduction.s3-sa-east-1.amazonaws.com/resources/poster.jpg"
                videoId="video-2" />
            </Col>
          ) : (
            <Col lg={20} className="m-xs-20">
              <p>
                En CMT hemos mejorado nuestro proceso de facturación de convenios. A partir de abril de 2021 encontrarás en el correo que recibes a principios de cada mes un enlace para revisar las prestaciones realizadas el mes anterior, donde podrás asociar una o más órdenes de compra.
              </p>
              <p>
                A continuación, te dejamos un video explicativo de como adjuntar órdenes de compra:
              </p>
              <Player
                ref={player}
                poster="https://cmtsaludproduction.s3-sa-east-1.amazonaws.com/resources/poster.jpg"
                src="https://cmtsaludproduction.s3-sa-east-1.amazonaws.com/resources/oc.mp4"
                videoId="video-1" />
            </Col>
          )
        }
      </Row>
    </PublicLayout>

  )
}

export default AgreementTutorial

export const postQuery = graphql`
query GET_AGREEMENT_TUTORIAL($language: String!) {
  locales: allLocale(filter: {language: {eq: $language}}) {
    edges {
      node {
        ns
        data
        language
      }
    }
  }
}
`